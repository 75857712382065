import React from 'react';

import {
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Card, Typography } from '@pennfoster/pfc-design-system';
import { gpaFormatWithDecimals } from 'src/helpers/format';

import { Enrollment } from '../../types/enrollment';

interface GradesCardProps {
  enrollment: Enrollment;
}

export default function gradesCard({ enrollment }: GradesCardProps) {
  const formattedGpa = gpaFormatWithDecimals(enrollment.gpa);

  return (
    <Card pfVariant="default" borderColor="secondary.90">
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="subtitle1">My Grades</Typography>
              </TableCell>
              <TableCell aria-hidden="true" sx={{ textAlign: 'end' }}>
                &nbsp;
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell> Current GPA </TableCell>
              <TableCell sx={{ textAlign: 'end', fontWeight: 'bold' }}>
                {' '}
                {formattedGpa}{' '}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ borderBottom: '0' }}> Total Credits </TableCell>
              <TableCell sx={{ textAlign: 'end', borderBottom: '0' }}>
                {' '}
                <Typography fontWeight="bold" display="inline">
                  {enrollment.creditEarned}
                </Typography>{' '}
                /{enrollment.creditRequired}{' '}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
