import React, { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { updatePaymentMethod } from 'src/api';
import {
  CC_CVV_REGEX,
  CC_EXP_DATE_REGEX,
  ZIP_CODE_REGEX,
} from 'src/constants/regex';
import PaymentMethodContext from 'src/context/payment-method';
import { getErrorMessage } from 'src/helpers/handling-errors';
import { normalizeExpiry } from 'src/helpers/normalize';
import { NunitoSansErrorMessage } from 'src/styles';
import { PaymentMethod } from 'src/types/payments';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Button, Alert } from '@pennfoster/pfc-design-system';
import ProcessingState from '../../processing-state';
import ErrorMessage from '../error-message';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface IFormInput {
  nameOnCard: string;
  cardNumber: string;
  expiration: string;
  cvv: string;
  zipcode: string;
  isDefault?: boolean;
  isBackup?: boolean;
  lastFour?: string;
}

interface EditCardFormProps {
  paymentMethod?: PaymentMethod;
  handleClose: () => void;
}
const styleTextField = {
  height: '56px',
  margin: 0,
  padding: 0,
  width: '100%',
};

const schema = yup.object({
  expiration: yup
    .string()
    .length(5, 'Valid Format is MM/YY')
    .matches(CC_EXP_DATE_REGEX, 'Invalid Expiration Date')
    .test('is-expired', 'The date is expired', (value) => {
      const currentDate = new Date();
      const [month, year] = value!.split('/');

      const expirationDate = new Date(
        parseInt('20' + year),
        parseInt(month) - 1
      );

      return expirationDate >= currentDate;
    })
    .required(),
  cvv: yup.string().max(4).matches(CC_CVV_REGEX, 'Invalid CVV').required(),
  zipcode: yup
    .string()
    .max(10)
    .matches(ZIP_CODE_REGEX, 'Invalid Zip Code')
    .required(),
});

export default function EditCardForm({
  paymentMethod,
  handleClose,
}: EditCardFormProps) {
  const {
    errorsFromAPI,
    setErrorsFromAPI,
    paymentMethodSelected,
    setPaymentMethodSelected,
    setRefreshPaymentMethods,
  } = useContext(PaymentMethodContext);

  const [id, setId] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [, setValidationError] = useState<string>('');

  useEffect(() => {
    setId(paymentMethod?.id);
  }, []);

  const mutation = useMutation(async (data: IFormInput) => {
    setErrorsFromAPI('');
    setPaymentMethodSelected({
      nameOnCard: data.nameOnCard || '',
      cardNumber: data.cardNumber || '',
      expiration: data.expiration,
      zipCode: data.zipcode,
      isDefault: data.isDefault || false,
      isBackup: data.isBackup || false,
    });

    const creditCardInfo = {
      number: data.lastFour,
      expiration: data.expiration,
      cvv: data.cvv,
      nameOnCard: data.nameOnCard,
      isDefault: paymentMethod?.isDefault == true ? true : data.isDefault,
      isBackup: paymentMethod?.isBackup == true ? true : data.isBackup,
      address: {
        street1: '',
        street2: '',
        city: '',
        state: '',
        zip: data.zipcode,
      },
    };

    setIsLoading(true);
    updatePaymentMethod(id!, creditCardInfo)
      .then(() => {
        setIsLoading(false);
        setRefreshPaymentMethods(true);
        setPaymentMethodSelected({
          nameOnCard: '',
          cardNumber: '',
          expiration: '',
          zipCode: '',
          isDefault: false,
          isBackup: false,
        });
        handleClose();
      })
      .catch((error) => {
        if (error.response) {
          const errorMessage = getErrorMessage(error);
          setErrorsFromAPI(errorMessage);
        } else {
          setErrorsFromAPI(error.message);
        }
        setIsLoading(false);
      });
  });

  const onSubmit: SubmitHandler<IFormInput> = (data) => mutation.mutate(data);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({
    shouldUnregister: false,
    resolver: yupResolver(schema),
    defaultValues: {
      nameOnCard: paymentMethod?.nameOnCard,
      expiration: paymentMethodSelected?.expiration
        ? paymentMethodSelected?.expiration
        : paymentMethod?.expiration,
      zipcode: paymentMethodSelected?.zipCode,
      isDefault: paymentMethod?.isDefault,
      isBackup: paymentMethod?.isBackup,
    },
  });

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      setValidationError('Please correct the errors');
      setErrorsFromAPI('');
    } else {
      setValidationError('');
    }
  }, [errors]);

  function DefaultOption() {
    return (
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <FormControlLabel
          id="isDefault"
          name="isDefault"
          sx={{
            marginLeft: '0',
            display: 'flex',
            justifyContent: 'start',
            flexDirection: 'row',
          }}
          control={<Checkbox {...register('isDefault')} />}
          label={null}
        />
        <Typography>Set card as default</Typography>
      </Grid>
    );
  }

  function BackupOption({ isBackup }: { isBackup: boolean }) {
    return (
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <FormControlLabel
          id="isBackup"
          name="isBackup"
          sx={{
            marginLeft: '0',
            display: 'flex',
            justifyContent: 'start',
            flexDirection: 'row',
          }}
          control={
            <Checkbox
              onClick={() =>
                (paymentMethod!.isBackup = !paymentMethod?.isBackup)
              }
              defaultChecked={isBackup}
              {...register('isBackup')}
            />
          }
          label={null}
        />
        <Typography>Set card as backup</Typography>
      </Grid>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, maxWidth: '452px' }}>
      {!isLoading ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <hr
            style={{
              border: '1px solid rgba(200, 202, 204, 0.6)',
              maxWidth: '440px',
              margin: '0 0 16px 0',
              width: '100%',
            }}
          />
          <Grid container spacing={3} sx={{ padding: 0 }}>
            <Grid item xs={12}>
              <TextField
                sx={styleTextField}
                disabled={true}
                {...register('nameOnCard')}
                error={!!errors.nameOnCard}
                id="nameOnCard"
                name="nameOnCard"
                label="Card Name"
                type="text"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={styleTextField}
                InputProps={{ sx: { padding: 0 } }}
                disabled={true}
                {...register('cardNumber')}
                error={!!errors.cardNumber}
                id="cardNumber"
                name="cardNumber"
                label="Card Number"
                type="text"
                value={'•••• •••• •••• ' + paymentMethod?.lastFour}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                {...register('expiration')}
                error={!!errors.expiration}
                helperText={errors.expiration ? errors.expiration.message : ''}
                id="expiration"
                name="expiration"
                label="Expiration Date"
                InputProps={{ placeholder: 'MM/YY' }}
                inputProps={{ maxLength: 5 }}
                type="text"
                onChange={(event) => {
                  const { value } = event.target;
                  event.target.value = normalizeExpiry(value);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={styleTextField}
                {...register('cvv')}
                error={!!errors.cvv}
                helperText={errors.cvv ? errors.cvv.message : ''}
                id="cvv"
                name="cvv"
                label="CVV"
                type="password"
                inputProps={{ maxLength: 4 }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment sx={{ cursor: 'help' }} position="end">
                      <Tooltip title="For Mastercard, Visa, and Discover cards, the CVV is 3 digits on the back of the card. For American Express cards, the CVV is 4 digits on the front of the card.">
                        <HelpOutlineIcon />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={styleTextField}
                {...register('zipcode')}
                error={!!errors.zipcode}
                helperText={errors.zipcode ? errors.zipcode.message : ''}
                inputProps={{ maxLength: 10 }}
                id="zipcode"
                name="zipcode"
                label="Zip Code"
                type="text"
              />
            </Grid>
            {paymentMethod?.isDefault && <></>}
            {(paymentMethod?.isBackup ||
              (!paymentMethod?.isBackup && !paymentMethod?.isDefault)) && (
              <>
                <DefaultOption></DefaultOption>
                <BackupOption
                  isBackup={paymentMethod!.isBackup!}
                ></BackupOption>
              </>
            )}
            {errors && !errorsFromAPI && (
              <Grid item xs={12}>
                <ErrorMessage errors={errors} />
              </Grid>
            )}
            {errorsFromAPI && (
              <Grid item xs={12}>
                <Alert pfVariant="standard" severity="error">
                  <NunitoSansErrorMessage>
                    {errorsFromAPI}
                  </NunitoSansErrorMessage>
                </Alert>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button
                label="Cancel"
                onClick={handleClose}
                size="small"
                disabled={isLoading ? true : false}
              />
              <Button
                label="Save"
                pfVariant="filled"
                type="submit"
                size="small"
                disabled={isLoading ? true : false}
              />
            </Grid>
          </Grid>
        </form>
      ) : (
        <ProcessingState>
          Verifying card details with Chase Payment Solutions<sup>SM</sup>
        </ProcessingState>
      )}
    </Box>
  );
}
